import { useCallback, useEffect } from 'react';

/**
 * Hook to scroll to a selected tab in a horizontally scrollable container
 * @param {Object} options - Configuration options
 * @param {React.RefObject} options.containerRef - Reference to the tabs container
 * @param {number} options.selectedIndex - Index of the selected tab
 * @param {boolean} options.shouldScroll - Condition to determine if scrolling should happen
 */
export const useHorizontalScrollToTab = ({
  containerRef,
  selectedIndex,
  shouldScroll = true,
}) => {
  const scrollToSelectedTab = useCallback((ref, index) => {
    if (ref.current && index > 0) {
      const tabsContainer = ref.current;
      const tabElements = tabsContainer.querySelectorAll('a');

      if (tabElements[index]) {
        const selectedTabElement = tabElements[index];
        tabsContainer.scrollLeft =
          selectedTabElement.offsetLeft - tabsContainer.offsetLeft;
      }
    }
  }, []);

  useEffect(() => {
    if (shouldScroll) {
      scrollToSelectedTab(containerRef, selectedIndex);
    }
  }, [containerRef, selectedIndex, shouldScroll, scrollToSelectedTab]);

  // Return the scroll function in case it's needed imperatively
  return scrollToSelectedTab;
};
