import cn from 'classnames';
// Types
import type { GetStream_stream_store } from 'api/streams/types/GetStream';
import type {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import type { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
// Ui
import Image from 'ui/Image/Image';
// Ui3
import Text from 'ui3/Text/Text';
import AthleteMenu from 'components/Athlete/Description/components/AthleteMenu/AthleteMenu';
import TabsNavigation from '../TabsNavigation/TabsNavigation';
// Styles
import styles from './Description.module.scss';

export type MenuOption = {
  label: string;
  href: string;
};

type DescriptionProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  pending?: boolean;
};

const Description = ({ store, pending = false }: DescriptionProps) => {
  const { storeDetails } = store;
  const storeName = storeDetails?.storeName || '';
  const isLongerName = storeName.length > 20;
  const isLongestName = storeName.length >= 30;

  const avatarUrl = storeDetails?.avatarURL || '';

  return (
    <div>
      <div className={styles.profileHeader}>
        <div className={styles.athleteInfo}>
          <div className={styles.imageWrapper}>
            <Image
              className={styles.image}
              src={avatarUrl || '/placeholder.svg'}
              alt={storeName}
              sizes="20vw"
            />
          </div>

          <div className={styles.athleteName}>
            <Text
              variant="h4"
              className={cn(styles.name, {
                [styles.longerName]: isLongerName,
                [styles.longestName]: isLongestName,
              })}
            >
              {storeName}
            </Text>
            <Text variant="body1Medium16" className={styles.handle}>
              {`@${store.slug}`}
            </Text>
          </div>
        </div>
        <AthleteMenu store={store} className={styles.actionButtons} />
      </div>
      <TabsNavigation store={store} pending={pending} />
    </div>
  );
};

export default Description;
