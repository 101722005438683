import { useState } from 'react';
import cn from 'classnames';
// Helpers
import { getCBEnvLink } from 'helpers/routes';
// Types
import { UserRole } from 'api/graphql-global-types';
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
// UI
import Modal from 'ui3/Modal/Modal';
import Image from 'ui3/Image/Image';
import Button from 'ui3/Button/Button';
import Text from 'ui3/Text/Text';
// Components
import FollowButton from 'components/FollowButton/FollowButton';
import HubSpotForm from 'components/HubspotForm/HubSpotForm';
// Styles
import styles from './EmptyPage.module.scss';

export type EmptyPageNavTabType = 'home' | 'shop' | 'channel' | 'media';

type EmptyPageProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  navTabType: EmptyPageNavTabType;
};

const EmptyPage = ({ store, navTabType }: EmptyPageProps) => {
  const [isHubspotModalOpen, setIsHubSpotModalOpen] = useState<boolean>(false);

  const openHubspotModal = () => {
    setIsHubSpotModalOpen(true);
  };
  const closeHubspotModal = () => {
    setIsHubSpotModalOpen(false);
  };

  const avatarUrl = store.storeDetails?.avatarURL || '';
  const storeName = store.storeDetails?.storeName || '';

  const isHome = navTabType === 'home';

  const contentHeading = isHome
    ? 'Book or Sponsor me'
    : navTabType === 'shop'
    ? 'Products Coming Soon'
    : navTabType === 'channel'
    ? 'Streams Coming Soon'
    : 'Media Coming Soon';

  const description = isHome
    ? 'I’m ready to work with your brand, book me today!'
    : navTabType === 'shop'
    ? 'Follow to get notified about New Products, Merch Drops, and Experiences'
    : navTabType === 'channel'
    ? 'Follow to get notified when I’ll Go Live next!'
    : 'Follow to get notified about New Media Posts!';

  const onBookMeClick = () => {
    if (store.role === UserRole.Organization) {
      openHubspotModal();
    } else {
      window.open(`${getCBEnvLink()}/booking/${store.id}`, '_blank');
    }
  };

  const renderFollowMeButton = () => {
    return (
      <div className={styles.buttonWrapper}>
        <FollowButton
          className={styles.button}
          store={store}
          startIcon="plus"
          unfollowText="Following"
          unfollowStartIcon="check"
          followColor="accent-default"
          unFollowColor="accent-default"
          xs="large"
        />
      </div>
    );
  };

  const renderHubSpotButton = () => {
    return (
      <div className={styles.buttonWrapper}>
        <Button className={styles.button} onClick={onBookMeClick} icon="mail">
          Book me
        </Button>
        <Modal
          size="medium"
          open={isHubspotModalOpen}
          onClose={closeHubspotModal}
        >
          <HubSpotForm />
        </Modal>
      </div>
    );
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.homeBackground]: isHome,
      })}
    >
      <div className={styles.imageWrapper}>
        <Image
          className={styles.image}
          src={avatarUrl}
          alt={storeName}
          objectFit="cover"
        />
      </div>
      <div className={styles.contentWrapper}>
        <Text variant="h2" className={styles.contentHeading}>
          {contentHeading}
        </Text>
        <Text
          variant="body1Regular18"
          color="lights-medium"
          className={styles.description}
        >
          {description}
        </Text>
        {isHome ? renderHubSpotButton() : renderFollowMeButton()}
      </div>
    </div>
  );
};
export default EmptyPage;
