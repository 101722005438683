import cn from 'classnames';
import { useState } from 'react';
// Helpers
import { getCBEnvLink } from 'helpers/routes';
// Types
import { UserRole } from 'api/graphql-global-types';
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import {
  GetMediaPost_getMediaPost_ImagePost_store,
  GetMediaPost_getMediaPost_VideoPost_store,
} from 'api/mediaPost/types/GetMediaPost';
// UI
import Modal from 'ui3/Modal/Modal';
// UI2
import Button from 'ui3/Button/Button';
// Components
import FollowButton from 'components/FollowButton/FollowButton';
import HubSpotForm from 'components/HubspotForm/HubSpotForm';
// Styles
import styles from './AthleteMenu.module.scss';

type AthleteMenuProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  className?: string;
};

const AthleteMenu = ({ store, className }: AthleteMenuProps) => {
  const [isFollowed, setIsFollowed] = useState<boolean>(false);
  const [isHubspotModalOpen, setIsHubSpotModalOpen] = useState<boolean>(false);

  const openHubspotModal = () => {
    setIsHubSpotModalOpen(true);
  };
  const closeHubspotModal = () => {
    setIsHubSpotModalOpen(false);
  };

  const onBookMeClick = () => {
    if (store.role === UserRole.Organization) {
      openHubspotModal();
    } else {
      window.open(`${getCBEnvLink()}/booking/${store.id}`, '_blank');
    }
  };

  return (
    <div className={cn(styles.buttonsWrapper, className)}>
      <FollowButton
        className={cn(styles.actionButton, {
          [styles.textColorWhite]: isFollowed,
        })}
        store={store}
        startIcon="plus"
        unfollowText="Following"
        unfollowStartIcon="check"
        followColor="primary"
        unFollowColor="secondary"
        onChange={setIsFollowed}
        xs="medium"
      />
      {/* <Button TODO Tip functionality
        variant="secondary"
        className={cn(styles.actionButton, styles.tipMeButton)}
        onClick={onBookMeClick}
        size="medium"
        icon="heart"
      >
        Tip
      </Button> */}
      <Button
        variant="secondary"
        className={cn(styles.actionButton, styles.bookMeButton)}
        onClick={onBookMeClick}
        size="medium"
        icon="mail"
      >
        Book Me
      </Button>
      <Modal
        size="medium"
        open={isHubspotModalOpen}
        onClose={closeHubspotModal}
      >
        <HubSpotForm />
      </Modal>
    </div>
  );
};

export default AthleteMenu;
