import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// Types
import { TabOptions } from '../TabsNavigation';
// UI
import { IconNames } from 'ui3/MillionsIcon/MillionsIcon';
import LinkButton from 'ui3/LinkButton/LinkButton';
// Styles
import styles from './Tab.module.scss';

type TabProps = TabOptions & {
  pageAddress: string;
};

const iconMap: Record<string, IconNames> = {
  merch: 'shirtOutline',
  memorabilia: 'award',
  products: 'shoppingBag',
  experiences: 'starOutline',
  image: 'image',
  video: 'video',
};

const Tab = ({ name, url, pageAddress }: TabProps) => {
  const [mediaType, setMediaType] = useState<string | null>(null);
  const { query } = useRouter();

  const isSelected =
    name !== 'media feed'
      ? name.toLowerCase() === pageAddress || pageAddress === '[streamSlug]'
      : pageAddress === 'media' || pageAddress === '[mediaPostSlug]';

  useEffect(() => {
    const mediaTypeFromUrl = query.type
      ? Array.isArray(query.type)
        ? query.type[0]
        : query.type
      : null;
    setMediaType(mediaTypeFromUrl);
  }, [query.type]);

  // Add more pages to this list if needed
  const isMediaTypeSelected = ['media'].includes(pageAddress)
    ? mediaType
      ? name.toLowerCase() === mediaType
      : name.toLowerCase() === 'all'
    : false;

  // If icon maps to category, use it
  const icon = iconMap[name.toLowerCase()] ?? null;

  return (
    <div className={styles.tabWrapper}>
      <LinkButton
        variant="secondary"
        link={url}
        className={cn(styles.tab, {
          [styles.highlightedTab]: isSelected || isMediaTypeSelected,
        })}
        {...(icon && { icon })}
      >
        {name}
      </LinkButton>
    </div>
  );
};

export default Tab;
