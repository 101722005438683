import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
import { useRef } from 'react';
import { isMobile } from 'react-device-detect';
// Hooks
import { useGetStreams, useHorizontalScrollToTab } from 'hooks';
// Types
import {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import { StreamStatus } from 'api/graphql-global-types';
// Components
import Tab from './Tab/Tab';
import EmptyPage, { EmptyPageNavTabType } from './EmptyPage/EmptyPage';
// Styles
import styles from './TabsNavigation.module.scss';

export type TabOptions = {
  name: string;
  url: string;
};

type ExtendedTabOptions = TabOptions & {
  path: string;
};

type TabsNavigationProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  pending?: boolean;
};

const TabsNavigation = ({ store, pending }: TabsNavigationProps) => {
  const { asPath, query, pathname } = useRouter();
  const { athleteSlug } = query;

  // Refs for scrollable containers
  const shopTabsRef = useRef<HTMLDivElement>(null);
  const mediaTabsRef = useRef<HTMLDivElement>(null);
  const mainTabsRef = useRef<HTMLDivElement>(null);

  // split the page address to get current address.
  const pageAddress =
    pathname.split('/').pop() === '[athleteSlug]'
      ? ''
      : pathname.split('/').pop() || '';

  const baseProfilePath = '/' + athleteSlug;

  const shopTabList: ExtendedTabOptions[] = [];

  if (store.hasMerch) {
    shopTabList.push({
      name: 'Merch',
      path: 'merch',
      url: baseProfilePath + '/merch',
    });
  }

  if (store.hasMemorabilia) {
    shopTabList.push({
      name: 'Memorabilia',
      path: 'memorabilia',
      url: baseProfilePath + '/memorabilia',
    });
  }

  if (store.hasPureProducts) {
    shopTabList.push({
      name: 'products',
      path: 'products',
      url: baseProfilePath + '/products',
    });
  }

  if (store.hasExperiences) {
    shopTabList.push({
      name: 'experiences',
      path: 'experiences',
      url: baseProfilePath + '/experiences',
    });
  }

  const mediaTabList: ExtendedTabOptions[] = [];

  if (store.hasMediaPost) {
    mediaTabList.push({
      name: 'All',
      path: 'media',
      url: baseProfilePath + '/media',
    });

    mediaTabList.push({
      name: 'Image',
      path: 'media',
      url: baseProfilePath + '/media?type=image',
    });

    mediaTabList.push({
      name: 'Video',
      path: 'media',
      url: baseProfilePath + '/media?type=video',
    });
  }

  const articleTabList: ExtendedTabOptions[] = [];

  if (store.hasArticle) {
    articleTabList.push({
      name: 'news',
      path: 'news',
      url: baseProfilePath + '/news',
    });
  }

  // using pageAddress to detect if selected option is home, shop or channel
  const isHome = pageAddress === '';
  const isAbout = pageAddress === 'about';

  const isShop =
    !!shopTabList.find((tab) => tab.path === pageAddress) ||
    pageAddress === 'merch'; // in case of an empty page

  const isMedia =
    !!mediaTabList.find((tab) => tab.path === pageAddress) ||
    pageAddress === '[mediaPostSlug]' ||
    pageAddress === 'media'; // in case of an empty page

  const isContent = 'streams' === pageAddress || pageAddress === '[streamSlug]';
  const isArticle = 'news' === pageAddress || pageAddress === '[newsSlug]';

  // Get URL params to determine which tab is selected
  const { type } = query;

  // Determine selected tab index
  const selectedMainTabIndex = [
    isHome,
    isAbout,
    isContent,
    isMedia,
    isShop,
    isArticle,
  ].findIndex(Boolean);
  const selectedShopTabIndex = shopTabList.findIndex(
    (tab) => tab.path === pageAddress
  );
  const selectedMediaTabIndex = type
    ? mediaTabList.findIndex((tab) => tab.url.includes(`type=${type}`))
    : mediaTabList.findIndex((tab) => tab.name === 'All');

  // Effect to scroll to selected tab if needed for mobile
  useHorizontalScrollToTab({
    containerRef: shopTabsRef,
    selectedIndex: selectedShopTabIndex,
    shouldScroll: isMobile && isShop,
  });

  useHorizontalScrollToTab({
    containerRef: mediaTabsRef,
    selectedIndex: selectedMediaTabIndex,
    shouldScroll: isMobile && isMedia,
  });

  useHorizontalScrollToTab({
    containerRef: mainTabsRef,
    selectedIndex: selectedMainTabIndex,
    shouldScroll: isMobile,
  });

  const skipGetStreams =
    !store.hasActiveStreams || isShop || pageAddress === 'media';

  const { data: streamsData } = useGetStreams({
    variables: {
      input: {
        storeId: store?.id,
        streamStatus: [
          StreamStatus.Active,
          StreamStatus.Paused,
          StreamStatus.Interrupted,
          StreamStatus.Scheduled,
          StreamStatus.Ended,
        ],
      },
      withSponsors: true,
    },
    skip: skipGetStreams,
  });

  const activeStream = streamsData?.streamsV2.entities?.find(
    (stream) => stream.streamStatus === StreamStatus.Active
  );

  const hasShop = shopTabList.length;
  const hasContent = store.hasStreams;
  const hasMediaPost = store.hasMediaPost;
  const hasNews = store.hasArticle;
  const hasHome =
    hasShop || hasContent || store.hasAma || hasMediaPost || hasNews;

  const shopUrl = hasShop ? shopTabList[0].url : baseProfilePath + '/merch'; // second option is for empty shop, it will display the empty page component
  const channelUrl = baseProfilePath + '/streams'; // second option is for empty channel, it will display the empty page component
  const mediaUrl = baseProfilePath + '/media';
  const newsUrl = baseProfilePath + '/news';
  const aboutUrl = baseProfilePath + '/about';

  const isEmptyHome = baseProfilePath === asPath && !hasHome;
  const isEmptyShop = pageAddress === 'merch' && !hasShop;
  const isEmptyMedia = pageAddress === 'media' && !hasMediaPost;
  const isEmptyChannel = pageAddress === 'streams' && !hasContent;

  const showEmptyPage =
    isEmptyHome || isEmptyShop || isEmptyChannel || isEmptyMedia; // flag that indicates if there is an empty component or not
  const emptyPageType = isEmptyHome
    ? 'home'
    : isEmptyShop
    ? 'shop'
    : isEmptyChannel
    ? 'channel'
    : 'media'; // display message meant for specific empty component

  const showSeparator = activeStream || isShop || showEmptyPage; // this is the line that separates base options from suboptions and empty component

  const renderEmptyPage = (navTabType: EmptyPageNavTabType) => {
    return (
      <div className={styles.emptyPageWrapper}>
        <EmptyPage store={store} navTabType={navTabType} />
      </div>
    );
  };

  if (pending) {
    return (
      <>
        <div className={styles.navGroup}>
          <div
            className={cn(styles.navOption, {
              [styles.highlightedNavOption]: isHome,
            })}
          >
            <Link href={baseProfilePath}>home</Link>
          </div>
        </div>
        {isEmptyHome && renderEmptyPage('home')}
      </>
    );
  }

  return (
    <>
      <div className={styles.navGroup} ref={mainTabsRef}>
        <Link href={baseProfilePath} passHref>
          <a
            className={cn(styles.navOption, {
              [styles.highlightedNavOption]: isHome,
            })}
          >
            Home
          </a>
        </Link>
        <Link href={aboutUrl} passHref>
          <a
            className={cn(styles.navOption, {
              [styles.highlightedNavOption]: isAbout,
            })}
          >
            About
          </a>
        </Link>
        <Link href={channelUrl} passHref>
          <a
            className={cn(styles.navOption, {
              [styles.highlightedNavOption]: isContent,
            })}
          >
            Channel
          </a>
        </Link>
        <Link href={mediaUrl} passHref>
          <a
            className={cn(styles.navOption, {
              [styles.highlightedNavOption]: isMedia,
            })}
          >
            Media
          </a>
        </Link>
        <Link href={shopUrl} passHref>
          <a
            className={cn(styles.navOption, {
              [styles.highlightedNavOption]: isShop,
            })}
          >
            Shop
          </a>
        </Link>
        {hasNews && (
          <Link href={newsUrl} passHref>
            <a
              className={cn(styles.navOption, {
                [styles.highlightedNavOption]: isArticle,
              })}
            >
              Articles
            </a>
          </Link>
        )}
      </div>
      <div className={cn({ [styles.navSeparator]: showSeparator })}></div>
      <div className={styles.tabsSection}>
        <div
          ref={shopTabsRef}
          className={cn(styles.hidden, {
            [styles.showTabs]: isShop && hasShop,
            [styles.allowOverflow]: isShop,
          })}
        >
          {shopTabList.map(({ name, url }) => (
            <Tab key={name} name={name} url={url} pageAddress={pageAddress} />
          ))}
        </div>
        <div
          ref={mediaTabsRef}
          className={cn(styles.hidden, {
            [styles.showTabs]: isMedia && hasMediaPost,
            [styles.allowOverflow]: isMedia,
          })}
        >
          {mediaTabList.map(({ name, url }) => (
            <Tab key={name} name={name} url={url} pageAddress={pageAddress} />
          ))}
        </div>
      </div>
      {showEmptyPage && renderEmptyPage(emptyPageType)}
    </>
  );
};

export default TabsNavigation;
